import { AnimatedLink } from '@components/shared';
import { useLocation } from '@reach/router';
import React, { useCallback } from 'react';

import {
    menu__open,
    menu__closed,
    menu__nav,
    menu__nav__list__item,
    selected,
} from './header.module.scss';
import { INavItem, IMenu, IClickableDiv } from './types';

const navLinks = [
    {
        name: 'Akademia',
        url: '/',
    },
    {
        name: 'Ściezki rozwoju',
        url: '/roadmaps',
    },
    {
        name: 'Blog',
        url: '/blog',
    },
    {
        name: 'Wykłady',
        url: '/records',
    },
    {
        name: 'Kontakt',
        url: '/#contact',
    },
];

// uniwersalny
const ClickableDiv: React.FC<IClickableDiv> = (props) => <div {...props} />;

const Menu: React.FC<IMenu> = ({ isMenuOpen, toggleMenu }) => {
    const handleLinkClick = useCallback(
        (e) => {
            if (e.target.hasAttribute('href')) toggleMenu();
        },
        [isMenuOpen],
    );
    const location = useLocation();

    return (
        <ClickableDiv
            className={isMenuOpen ? menu__open : menu__closed}
            onClick={handleLinkClick}>
            <nav className={menu__nav}>
                <ul>
                    {navLinks.map(({ name, url, button }) => {
                        return (
                            <li key={name}>
                                <AnimatedLink
                                    to={url}
                                    className={`${menu__nav__list__item} ${
                                        location.pathname === url
                                            ? selected
                                            : ''
                                    }`}>
                                    {name}
                                </AnimatedLink>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </ClickableDiv>
    );
};

export default Menu;
