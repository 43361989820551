import React, { useEffect } from 'react';

const ScriptAfterInteraction = ({ children, id, ...props }) => {
    useEffect(() => {
        window.addEventListener('scroll', listener, {
            passive: true,
            once: true,
        });
        window.addEventListener('click', listener, {
            passive: true,
            once: true,
        });

        if (sessionStorage['s' + id]) {
            listener();
        }

        return () => {
            window.removeEventListener('scroll', listener);
            window.removeEventListener('click', listener);
        };

        function listener() {
            sessionStorage['s' + id] = true;
            window.removeEventListener('scroll', listener);
            window.removeEventListener('click', listener);
            (window.requestIdleCallback || window.requestAnimationFrame)(() => {
                const script = Object.entries(props).reduce(
                    (script, [key, value]) => {
                        script[key] = value;
                        return script;
                    },
                    document.createElement('script'),
                );
                if (children) {
                    script.textContent = children;
                }
                document.body.appendChild(script);
            });
        }
    }, []);

    return null;
};

export default ScriptAfterInteraction;
