// extracted by mini-css-extract-plugin
export var sideLeft = "n_bj";
export var sideRight = "n_bk";
export var main = "n_bl";
export var plam__right__first = "n_bm";
export var plam__right__second = "n_bn";
export var plam__right__third = "n_bp";
export var plam__left__first = "n_bq";
export var plam__left__second = "n_br";
export var plam__left__third = "n_bs";
export var small = "n_R";
export var up = "n_bt";
export var jumping = "n_bv";