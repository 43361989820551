/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { StructuredData } from '@components/layout';
import React from 'react';
import Helmet from 'react-helmet';

import Favicon from './favicon.ico';

const siteBasic = {
    title: '',
    description: '',
    lang: '',
};

const seoBasic = {
    title: 'Page',
    opengraphTitle: '',
    metaKeywords: '',
    metaDesc: '',
    metaRobotsNofollow: '',
    metaRobotsNoindex: '',
    opengraphDescription: '',
    opengraphImage: null,
};

function Head({ siteMetaData = {}, seo = {}, ogType = 'website', location }) {
    return (
        <Helmet
            htmlAttributes={{
                lang: 'pl', // pl_PL jest nieprawidłowy
            }}
            title={`${seo.title}`}
            meta={[
                {
                    name: 'description',
                    content:
                        seo.opengraphDescription || siteMetaData.description,
                },
                {
                    name: 'keywords',
                    content: seo.focuskw || '',
                },
                {
                    property: 'og:title',
                    content:
                        seo.title ||
                        siteMetaData.title ||
                        siteBasic.description,
                },
                {
                    property: 'og:description',
                    content:
                        seo.opengraphDescription ||
                        siteMetaData.description ||
                        siteBasic.description,
                },
                {
                    property: 'og:image',
                    content: seo.opengraphImage
                        ? seo.opengraphImage.sourceUrl
                        : '',
                },
                {
                    property: 'og:image:alt',
                    content: seo.title,
                },
                {
                    property: 'og:type',
                    content: ogType,
                },
                {
                    property: 'og:url',
                    content: location.href,
                },
            ]}>
            {/* favicon */}
            <link rel="icon" href={Favicon} />
            <link
                rel="preconnect dns-prefetch"
                href="https://cdnjs.cloudflare.com"
            />

            {/* Czcionki */}
            {/* <link
                rel="preconnect dns-prefetch"
                href="https://fonts.googleapis.com"
            /> */}
            {/* <link rel="preconnect" href="https://fonts.gstatic.com" /> */}
            {/* <link
                rel="preload"
                href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&display=swap"
                as="style"
                onload="this.rel='stylesheet'"></link> */}
            {/* <link
                href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&display=swap"
                rel="stylesheet"
                type="text/css"
            /> */}

            {/* <!-- Hotjar Tracking Code for https://academy.localhost-group.com/ --> */}

            {/* <script async defer type="text/javascript">
                {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2253969,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
            </script> */}

            <link
                rel="preload"
                as="style"
                type="text/css"
                charset="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                onload="this.rel='stylesheet'"
            />
            <link
                rel="preload"
                as="style"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                onload="this.rel='stylesheet'"
            />

            <script
                async
                defer
                type="text/javascript"
                src="https://apis.google.com/js/api.js"
            />

            {/* <script
                async
                defer
                src="https://www.googletagmanager.com/gtag/js?id=G-047JL2P783"></script> */}
            {/* <script async defer type="text/javascript">
                {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-047JL2P783');
            `}
            </script> */}

            {/* <script async defer type="text/javascript">
                {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '462713818278420');
      fbq('track', 'PageView');`}
            </script> */}

            <noscript>
                {`
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=462713818278420&ev=PageView&noscript=1%22"
        />`}
            </noscript>

            <noscript>
                {`<img src="https://track.mailerlite.com/webforms/o/4180717/g4c8h2?v1622301916" width="1" height="1" style="max-width:1px;max-height:1px;visibility:hidden;padding:0;margin:0;display:block" alt="." border="0"/>`}
            </noscript>

            {/* <script async defer>
                {`function ml_webform_success_4180717(){var r=ml_jQuery||jQuery;r(".ml-subscribe-form-4180717 .row-success").show(),r(".ml-subscribe-form-4180717 .row-form").hide()}`}
            </script>

            <script
                async
                defer
                src="https://static.mailerlite.com/js/w/webforms.min.js?v0c75f831c56857441820dcec3163967c"
                type="text/javascript"></script> */}
        </Helmet>
    );
}

export default Head;
