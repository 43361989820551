// extracted by mini-css-extract-plugin
export var blocked = "m_X";
export var loader = "m_G";
export var button = "m_l";
export var nav__links__button = "m_Y";
export var header = "m_Z";
export var nav = "m_0";
export var nav__links = "m_1";
export var nav__links__link = "m_2";
export var menu__closed = "m_3";
export var menu__open = "m_4";
export var menu__nav = "m_5";
export var menu__nav__list__item = "m_6";
export var hamburger = "m_7";
export var hamburger__box = "m_8";
export var hamburger__box__inner = "m_9";
export var hamburger__box__inner__active = "m_bb";
export var selected = "m_bc";
export var changer__wrapper = "m_bd";
export var logo = "m_bf";
export var active = "m_bg";
export var smaller = "m_bh";