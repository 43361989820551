import { ScriptAfterInteraction } from '@components/logic';
import React from 'react';

const ThirdPartyScripts = () => (
    <>
        <ScriptAfterInteraction
            id="htj"
            async
            defer
            type="text/javascript"
            children={hotjarScript}
        />
        <ScriptAfterInteraction
            id="gtag"
            async
            defer
            type="text/javascript"
            children={gtagScripts}
        />
        <ScriptAfterInteraction
            id="glm"
            async
            defer
            src="https://www.googletagmanager.com/gtag/js?id=G-047JL2P783"
        />
        <ScriptAfterInteraction
            id="fpxl"
            async
            defer
            type="text/javascript"
            children={facebookPixelScripts}
        />
        <ScriptAfterInteraction 
            id="wbf"
            async
            defer
            children={webFormScripts}
        />
        <ScriptAfterInteraction
            id="mlr"
            async
            defer
            src="https://static.mailerlite.com/js/w/webforms.min.js?v0c75f831c56857441820dcec3163967c"
            type="text/javascript"
        />
    </>
);

export default ThirdPartyScripts;

const hotjarScript = `(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:2253969,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

const gtagScripts = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-047JL2P783');
`;

const facebookPixelScripts = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '462713818278420');
fbq('track', 'PageView');`;

const webFormScripts = `function ml_webform_success_4180717(){var r=ml_jQuery||jQuery;r(".ml-subscribe-form-4180717 .row-success").show(),r(".ml-subscribe-form-4180717 .row-form").hide()}`