import { Header, Head, Footer } from '@components/layout';
import { AnimatedButton, Modal } from '@components/shared';
import { useLocation } from '@reach/router';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import LeftSVG from './LeftSVG.svg';
import RightSVG from './RightSVG.svg';
import ThirdPartyScripts from './Scripts';
import ArrowSVG from './arrow';
import { main, up } from './layout.module.scss';
import {
    plam__left__first,
    plam__left__second,
    plam__left__third,
    plam__right__second,
    plam__right__first,
    plam__right__third,
    small,
} from './layout.module.scss';

const Layout = ({
    children,
    siteMetaData,
    seo,
    imBusy,
    translationUrl,
    headerDisabled,
}) => {
    const [plams, setPlams] = useState(0);
    let defaultTranslation = {
        pl: '/pl',
        en: '/en',
    };
    const modal = useRef();
    if (translationUrl) {
        defaultTranslation = translationUrl;
    }
    useEffect(() => {
        setPlams(Math.ceil(window.document.body.clientHeight / 1750));
    }, []);

    const location = useLocation();

    const scrollUp = () => {
        if (typeof window !== 'undefined') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <div
            style={{
                overflow: 'hidden',
                position: 'relative',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
            <Head seo={seo} siteMetaData={siteMetaData} location={location} />
            {!headerDisabled && <Header translationUrl={defaultTranslation} />}

            {[...Array(plams).keys()].map((_, i) => {
                if (typeof PlamTable[i] !== 'undefined') {
                    return (
                        <img
                            alt=""
                            src={PlamTable[i].svg}
                            className={PlamTable[i].className}
                            key={i}
                        />
                    );
                }
            })}

            <main className={main}>{children}</main>
            <div className={up} onClick={scrollUp}>
                <ArrowSVG />
            </div>
            <Footer />
            <ThirdPartyScripts />
        </div>
    );
};

export default Layout;

const PlamTable = [
    {
        svg: LeftSVG,
        className: plam__left__first,
    },
    {
        svg: RightSVG,
        className: plam__right__first,
    },
    {
        svg: LeftSVG,
        className: plam__left__second,
    },
    {
        svg: RightSVG,
        className: plam__right__second,
    },
    {
        svg: LeftSVG,
        className: plam__left__third,
    },
    {
        svg: RightSVG,
        className: plam__right__third,
    },
];
