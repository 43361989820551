import React from 'react';

const ArrowSVG = ({ style }) => {
    return (
        <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}>
            <path
                d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z"
                fill="#11151F"
            />
        </svg>
    );
};

export default ArrowSVG;
